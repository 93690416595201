import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { containerMaxWidth } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import { useLocation } from "@reach/router"

const Container = styled.div`
  max-width: ${containerMaxWidth};
  margin: 14.2rem auto;
  padding: 0 3rem;
`

const Title = styled.h1`
  padding: 3rem;
  text-align: center;
`

const Text = styled.p`
  padding: 0 6rem;
  text-align: center;
`

const ErrorOutOfStockPage = ({ pageContext }) => {
  const location = useLocation()
  const { error } = location.state || {}
  const { t } = useTranslation("translations")
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  return (
    <LayoutComponent
      meta={{ title: "Product is out of stock!" }}
      invertedHeader
      pageContext={pageContext}
    >
      {error && error === "Out of stock" ? (
        <Container>
          <Title>{t("Product is out of stock!")}</Title>
          <Text>
            {t("We are working on getting the product back in stock.")}
          </Text>
        </Container>
      ) : (
        <Container>
          <Title>{t("In someone's cart")}</Title>
          <Text>
            {t("The product is fully reserved, please check in soon again!")}
          </Text>
        </Container>
      )}
    </LayoutComponent>
  )
}

export default ErrorOutOfStockPage
